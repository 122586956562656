import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import styled from "styled-components";
import QRCode from "qrcode.react";

import {
  FormContainer,
  BankingBtn,
  QRModalContent,
  QRHolder,
} from "./CommonStyles";

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 256px;
`;

function Login(props) {
  const navigate = useNavigate();
  const [invitation, setInvitation] = useState("");
  const [activeConnection, setActiveConnection] = useState("");
  const [toggleLogin, setToggleLogin] = useState(false);
  const [toggleCreateAccount, setToggleCreateAccount] = useState(false);

  useEffect(() => {
    if (toggleLogin || toggleCreateAccount) {
      Axios({
        method: "POST",
        url: "/api/invitations",
      })
        .then((res) => {
          console.log('Invitation: ', res.data)
          setInvitation(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [toggleLogin, toggleCreateAccount]);

  useEffect(() => {
    if (invitation && toggleLogin) {
      Axios({
        method: "POST",
        url: "/api/verifications",
        data: {
          invitation_id: invitation.invitation_id,
        },
      })
        .then((res) => {
          console.log("Verification: ", res.data);
          props.setVerifiedCredentials(res.data.verificationRecords);
          navigate("/account");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [invitation, toggleLogin]);

  useEffect(() => {
    if (invitation && toggleCreateAccount) {
      Axios({
        method: "GET",
        url: `/api/connections/${invitation.invitation_id}`,
      })
        .then((connection) => {
          console.log('connection: ', connection)
          setActiveConnection(connection);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [invitation, toggleCreateAccount]);

  return (
    <FormContainer className="banking-agent">
      {/* <Form id="form" onSubmit={handleSubmit} ref={loginForm}>
            <InputBox>
                <Label htmlFor="user">Username</Label>
                <InputField type="text" name="user" id="user" required />
            </InputBox>
            <InputBox>
                <Label htmlFor="password">Password</Label>
                <InputField type="password" name="password" id="password" required />
            </InputBox>
            <SubmitBtn onClick={handleIssuer}>Go To Email Issuer</SubmitBtn>
            </Form> */}

      {!toggleLogin && !toggleCreateAccount ? (
        <>
        <div className="banking-box-row">
          <div className="one-third-column">
            <div className="loan-box">
              <h4>Apply for a Loan</h4>
              <BankingBtn>Apply Now</BankingBtn>
            </div>
          </div>
          <div className="one-third-column">
            <div className="account-box">
              <h4>Create an Account</h4>
              <BankingBtn onClick={() => setToggleCreateAccount(true)}>
                Get Started
              </BankingBtn>
              </div>
            </div>
          <div className="one-third-column">
            <div className="login-box">
              <h4>Log in</h4>
              <BankingBtn onClick={() => setToggleLogin(true)}>Log In</BankingBtn>
              </div>
          </div>
        </div>
        </>
      ) : toggleLogin ? (
        <div className="qr-box">
          <div className="qr-text">
            <h2>Connect with your account&nbsp;credential:</h2>
          </div>
          <QRModalContent>
            <QRHolder>
              {invitation ? (
                <QR
                  value={invitation.invitation_url}
                  size={256}
                  renderAs="svg"
                />
              ) : (
                <p>Loading...</p>
              )}
            </QRHolder>
          </QRModalContent>
        </div>
      ) : !activeConnection ? (
        <div className="qr-box">
          <div className="qr-text">
            <h2>First scan the invitation to connect:</h2>
          </div>
          <QRModalContent>
            <QRHolder>
              {invitation ? (
                <QR
                  value={invitation.invitation_url}
                  size={256}
                  renderAs="svg"
                />
              ) : (
                <p>Loading...</p>
              )}
            </QRHolder>
          </QRModalContent>
        </div>
      ) : (
        <div className="qr-box">
          <div className="qr-text">
            <h2>Credentials will be offered soon!</h2>
          </div>
        </div>
      )}
    </FormContainer>
  );
}
export default Login;
