import styled from 'styled-components'

export const AppContainer = styled.div`
  padding-top: 30px;
  box-sizing: border-box;
`
export const LogoHolder = styled.div`
  padding: 20px 0 10px 0;
  width: 100%;
`
export const Logo = styled.img`
  float: left;
  margin: 38px 20px;
  height: 78px;
`
export const NavLink = styled.a`
  display: block;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: bold;
  color: #0262CD;
  border: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export const PageSection = styled.div`
  overflow: hidden;
  margin: auto;
  padding: 0;
  width: 100%;
  color: ${(props) => props.theme.text_color};
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.background_primary};
`
export const AttributeTable = styled.table`
  margin-bottom: 1em;
  border-collapse: collapse;
`
export const AttributeRow = styled.tr`
  th {
    padding: 0 6px;
    text-align: left;
  }
`
export const FormContainer = styled.div`
  margin: 0 auto;
  padding: 0;
  min-width: 400px;
  width: 100%;
  height: 500px;
  text-align: center;
  background: ${(props) => props.theme.background_primary};
`
export const ButtonWrapper = styled.span`
  display: flex; 
  align-items: center;
  padding: 450px;
`
export const BankingBtn = styled.button`
  margin: 5px;
  border-radius: 15px 15px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  color: #0262CD;
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
  :hover {
    cursor: pointer;
  }
`
export const QRModalContent = styled.div`
  width: 100%;
`
export const QRHolder = styled.div`
  height: 256px;
`