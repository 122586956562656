import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import styled from 'styled-components'

import logo from "./assets/mountain-banking-logo.png";
import { ReactComponent as SearchSvg } from './assets/search.svg';
import { Logo, NavLink } from './UI/CommonStyles'

import "./App.css";
import Account from "./UI/Account";
import Login from "./UI/Login";

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 1600px;
  margin: 0 auto;
`
const HeaderOptions = styled.ul`
  float: left;
  margin: 57px 0 0 100px;
  padding: 0;
  list-style: none;
  align-items: center;

  li {
    float: left;
  }
`
const Search = styled.div`
  float: right;
  margin-top: 63px;
  color:  #222222;
`
const SearchBox = styled.div`
  float: left;
  margin-right: 10px;
  width: 240px;
  height: 25px;
  border-bottom: 1px solid #222;
`
const SearchIcon = styled(SearchSvg)`
  width: 25px;
  height: 25px;
`


function App() {
  const [verifiedCredArray, setVerifiedCredArray] = useState([]);
  const setVerifiedCredentials = (payload) => setVerifiedCredArray(payload);

  return (
    <Frame id="app-frame">
      <div className="header">
        
        <Logo src={logo} alt="Logo" />
      
        <HeaderOptions>
          <li><NavLink>Accounts</NavLink></li>
          <li><NavLink>Loans</NavLink></li>
          <li><NavLink>Investments</NavLink></li>
          <li><NavLink>Services</NavLink></li>
          <li><NavLink>Education</NavLink></li>
        </HeaderOptions>
      
        <Search>
          <SearchBox />
          <SearchIcon />
        </Search>
      </div>

      <div>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={<Login setVerifiedCredentials={setVerifiedCredentials} />}
            />
            <Route
              path="/account"
              element={<Account verifiedCredArray={verifiedCredArray} />}
              />
            <Route
              path="*"
              element={<Navigate to='/' />}
            />
          </Routes>
        </Router>

        <div className="banking-info-row">
          <p>This is a demonstration website to show the process of creating an account and logging in with verifiable credentials.</p>
          <p>Click the buttons above to start the workflow.</p>
          <p>Feel free to contact Indicio if you have questions! <a href="https://indicio.tech/contact/">You can reach Indicio here</a>.</p>
        </div>
      </div>

      <div className="footer">
        <div className="two-thirds-column">
          <div className="one-fourth-column">
            <ul>
              <li><a href="#">Accounts</a></li>
            </ul>
          </div>
          <div className="one-fourth-column">
            <ul>
              <li><a href="#">Loans</a></li>
              <li><a href="#">Investments</a></li>
              <li><a href="#">Services</a></li>
            </ul>
          </div>
          <div className="one-fourth-column">
            <ul>
              <li><a href="#">Education</a></li>
            </ul>
          </div>
          <div className="one-fourth-column">
            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <div className="one-third-column">
          <p>This is not an actual banking website and no financial services are offered.  Nothing on this site is to be construed as financial advice.</p>
          <p>Copyright @ 2023 Indicio, PBC</p>
        </div>
      </div>
    </Frame>
  );
}

export default App;
