import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { AttributeTable, AttributeRow, PageSection } from "./CommonStyles";

function Account({ verifiedCredArray }) {
  const [verifiedAttributes, setVerifiedAttributes] = useState({});

  useEffect(() => {
    if (verifiedCredArray.length) {
      let verifiedAttr = {};
      verifiedCredArray.forEach((record) => {
        record.result_data.forEach((attributes) => {
          verifiedAttr[attributes.name] = attributes.value;
        });
      });

      setVerifiedAttributes(verifiedAttr);
    }
  }, [verifiedCredArray]);

  if (!verifiedCredArray.length) {
    return <Navigate to={"/"} />;
  } else {
    return (
      <PageSection>
        {/* <div className="banking-banner">
        </div> */}
        <div className="banking-account">
          <h3>Welcome {verifiedAttributes.given_names}!</h3>
          <h6>Balance: $12,365.42</h6>
          <h5>Account Information</h5>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Account ID: </th>
                <td>{verifiedAttributes.account_id}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Account Established Date: </th>
                <td>{verifiedAttributes.account_established_date}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Username: </th>
                <td>{verifiedAttributes.username}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Institution ID: </th>
                <td>{verifiedAttributes.institution_id}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Institution Name: </th>
                <td>{verifiedAttributes.institution_name}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Surnames: </th>
                <td>{verifiedAttributes.surnames}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Given Names: </th>
                <td>{verifiedAttributes.given_names}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Of Birth: </th>
                <td>{verifiedAttributes.date_of_birth}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Email: </th>
                <td>{verifiedAttributes.email}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Phone Number: </th>
                <td>{verifiedAttributes.phone_number}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Account Access Type: </th>
                <td>{verifiedAttributes.account_access_type}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Credential Issued Date: </th>
                <td>{verifiedAttributes.credential_issued_date}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
        </div>
      </PageSection>
    );
  }
}

export default Account;
